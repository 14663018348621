<template>
  <section class="faq" v-show="faq?.length > 0">
    <div :class="device == 'mobile' ? '' : `container faq-container`">
      <h2 class="faq-hading">FAQ</h2>

      <div
        class="faq-item"
        v-for="(faq, index) in faqs"
        :key="index"
        @click="toggleFaq(index)"
      >
        <div class="faq-title-block">
          <h4 class="faq-title">
            {{ faq.title }}
          </h4>
          <div v-if="activeFaq === index" key="minus" class="faq-minus">
            <svg
              width="25"
              height="16"
              viewBox="0 0 25 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 14.5L10.9913 1.46786C11.3752 0.967118 12.1212 0.942707 12.537 1.41728L24 14.5"
                stroke="#444444"
                stroke-width="2"
              />
            </svg>
          </div>
          <div v-else key="plus" class="faq-plus">
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5 0H10.5V10.5H0V12.5H10.5V23H12.5V12.5H23V10.5H12.5V0Z"
                fill="#444444"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5 0H10.5V10.5H0V12.5H10.5V23H12.5V12.5H23V10.5H12.5V0Z"
                fill="#444444"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5 0H10.5V10.5H0V12.5H10.5V23H12.5V12.5H23V10.5H12.5V0Z"
                fill="#444444"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5 0H10.5V10.5H0V12.5H10.5V23H12.5V12.5H23V10.5H12.5V0Z"
                fill="#444444"
              />
            </svg>
          </div>
        </div>
        <div
          :style="{ maxHeight: activeFaq === index ? '1000px' : '0' }"
          :class="{ 'faq-content': activeFaq === index }"
        >
          <p
            :style="{ display: activeFaq === index ? 'block' : 'none' }"
            v-html="faq.content"
          ></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import faqsData from '../../faq.json'

export default {
  name: 'SectionFaq',
  data() {
    return {
      faqs: faqsData.faqs,
      activeFaq: null,
    }
  },
  methods: {
    toggleFaq(index) {
      this.activeFaq = this.activeFaq === index ? null : index
    },
  },
}
</script>

<style lang="scss" scoped>
.faq {
  @include mobile {
    padding: uniWM(50) 0;
  }
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  &-hading {
    width: 100%;
    text-align: center;
    @include desktopXL {
      margin-bottom: uniWXL(17);
      font-size: uniWXL(46);
    }
    @include desktop {
      margin-bottom: uniW(17);
      font-size: uniW(46);
    }
    @include mobile {
      margin-bottom: uniWM(10);
      font-size: uniWM(40);
    }
  }
  &-item {
    cursor: pointer;
    width: 100%;
    transition: background-color 0.2s ease-out;
    @include desktopXL {
      padding: uniWXL(20) 0;
    }
    @include desktop {
      padding: uniW(20) 0;
    }
    @include mobile {
      padding: uniWM(10) 0;
    }
  }

  &-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: color 0.2s ease;
    @include desktopXL {
      padding: 0 uniWXL(80);
    }
    @include desktop {
      padding: 0 uniW(80);
    }
    @include mobile {
      padding: 0 uniWM(18);
    }
  }

  &-title {
    font-weight: 500;
    @include desktopXL {
      font-size: uniWXL(24);
    }
    @include desktop {
      font-size: uniW(24);
    }
    @include mobile {
      font-size: uniWM(20);
    }
  }

  &-content {
    background-color: $orange;
    @include desktopXL {
      padding: uniWXL(20) uniWXL(80) uniWXL(30);
      margin-top: uniWXL(40);
      font-size: uniWXL(16);
    }
    @include desktop {
      padding: uniW(20) uniW(80) uniW(30);
      margin-top: uniW(40);
      font-size: uniW(16);
    }
    @include mobile {
      padding: uniWM(30) uniWM(18);
      margin-top: uniWM(20);
      font-size: uniWM(16);
    }
  }

  &-minus {
    svg {
      @include desktopXL {
        width: uniWXL(23);
      }
      @include desktop {
        width: uniW(23);
      }
      @include mobile {
        width: uniWM(23);
      }
    }
  }

  &-plus {
    svg {
      @include desktopXL {
        width: uniWXL(22);
        height: uniWXL(22);
      }
      @include desktop {
        width: uniW(22);
        height: uniWXL(22);
      }
      @include mobile {
        width: uniWM(15);
        height: uniWM(15);
      }
    }
  }
}
</style>
