<template>
  <div class="menu" :class="{ hidden: !menu }">
    <button @click="$emit('close')" type="button" class="menu-close">
      <img src="@/assets/icon/close.svg" />
    </button>
    <img src="@/assets/icon/logo.svg" class="menu-logo" />
    <!-- <nav>
      <ul>
        <li>
          <button @click="$emit('close'), scrollTo('#main')" type="button">
            Главная
          </button>
        </li>
        <li>
          <button @click="$emit('close'), scrollTo('#map')" type="button">
            Расположение
          </button>
        </li>
        <li>
          <button @click="$emit('close'), scrollTo('#booking')" type="button">
            Бронь
          </button>
        </li>
        <li>
          <button
            @click="$emit('close'), scrollTo('#apartments')"
            type="button"
          >
            Наши апартаменты
          </button>
        </li>
        <li>
          <button @click="$emit('close'), scrollTo('#contacts')" type="button">
            Контакты
          </button>
        </li>
      </ul>
    </nav> -->
    <button @click="$emit('close'), scrollTo('#main')" type="button">
      Главная
    </button>
    <button @click="$emit('close'), scrollTo('#map')" type="button">
      Расположение
    </button>
    <button @click="$emit('close'), scrollTo('#booking')" type="button">
      Бронь
    </button>
    <button @click="$emit('close'), scrollTo('#apartments')" type="button">
      О вилле
    </button>
    <button @click="$emit('close'), scrollTo('#contacts')" type="button">
      Контакты
    </button>
    <div class="line" />
    <a
      href="https://reservationsteps.ru/rooms/index/182864f9-5445-49a6-9dae-d80c42a047f2"
      target="_blank"
      class="btn btn-orange main-button"
    >
      забронировать номер
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { scroller } from 'vue-scrollto/src/scrollTo'
export default {
  name: 'NavMobile',
  data() {
    return {
      scrollTo: scroller(),
    }
  },
  computed: {
    ...mapState(['menu']),
  },
  watch: {
    menu(val) {
      if (val) document.body.style.overflow = 'hidden'
      else document.body.style.overflow = 'auto'
    },
  },
}
</script>

<style lang="scss" scoped>
.hidden {
  transform: translateY(-100%);
}
.menu {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: white;
  // padding-top: uniWM(78);
  overflow-y: scroll;
  // padding-bottom: uniWM(60);
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  &-logo {
    width: uniWM(71);
    margin: 0 auto;
  }
  ul {
    @include reset-list;
    li {
      text-align: center;
      margin-bottom: uniWM(40);
      &:last-child {
        margin-bottom: uniWM(60);
      }
    }
  }
  .line {
    width: uniWM(255);
    border-bottom: 1px solid $main-grey;
    margin: 0 auto;
  }
  .btn {
    margin: 0 auto;
    display: block;
    width: fit-content;
  }
  &-close {
    position: absolute;
    right: uniWM(20);
    top: uniWM(20);
    img {
      width: uniWM(20);
    }
  }
}
</style>
